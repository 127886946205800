import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import React from "react"
import CompanyList from "../components/companyList"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import { Container } from "../style/container"
import { ListContainer } from "../style/companyListContainer"
import { PagehtmlSerializer } from "../utils/pageHtmlSerializer"
import PropTypes from "prop-types"
import { mergeAndRemovDuplicates } from "../utils/array"

/**
 * @param {Object} data Translations
 */
const MobileCasinoPage = ({
  data: {
    prismic: {
      allMobilecasinopages,
      allCompanytables,
      allCompanys,
      second,
      third,
      allKeywordss,
      allFooters,
    },
  },
}) => {
  const {
    node: { meta_title, meta_description, meta_keywords, meta_img },
  } = allMobilecasinopages.edges[0]
  let allCompanysMerged = mergeAndRemovDuplicates(
    allCompanys.edges,
    second.edges
  )
  allCompanysMerged = mergeAndRemovDuplicates(allCompanysMerged, third.edges)
  return (
    <Layout
      keywords={allKeywordss}
      footer={allFooters}
      navigation={
        <Navigation
          title={allMobilecasinopages.edges[0].node.title}
          items={allMobilecasinopages.edges[0].node.body[0].fields}
          hight={"50vh"}
          smallNavigation={true}
          showBack={true}
        />
      }
    >
      <SEO
        meta={{
          title:
            meta_title && meta_title.length > 0 ? meta_title[0].text : null,
          description:
            meta_description && meta_description.length > 0
              ? meta_description[0].text
              : null,
          keywords:
            meta_keywords && meta_keywords.length > 0
              ? meta_keywords[0].text
              : null,
          img: !!meta_img ? meta_img.url : null,
          url: window.location.pathname,
        }}
      />
      <Container>
        <ListContainer>
          <CompanyList
            text={allCompanytables.edges[0].node}
            companies={allCompanysMerged}
            filter={"mobile-casino"}
          />
        </ListContainer>
        <RichText
          render={allMobilecasinopages.edges[0].node.text}
          htmlSerializer={PagehtmlSerializer}
        />
      </Container>
    </Layout>
  )
}
MobileCasinoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MobileCasinoPage

export const pageQuery = graphql`
  query($locale: String!) {
    prismic {
      allMobilecasinopages(lang: $locale) {
        edges {
          node {
            title
            text
            meta_title
            meta_description
            meta_keywords
            meta_img
            body {
              ... on PRISMIC_MobilecasinopageBodyMenu {
                fields {
                  url_text
                  url
                }
              }
            }
          }
        }
      }
      allCompanytables(lang: $locale) {
        edges {
          node {
            casino
            bonus
            free_spins
            rating
            play
            deposit_info
            paymentmethods
            footer
            nodata
          }
        }
      }
      allCompanys(lang: $locale, sortBy: name_ASC, first: 100) {
        edges {
          cursor
          node {
            name
            logo
            background
            url
            list
            bonus
            bonussub
            freespins
            freespinssub
            rating
            weight
            depositinfo
            paymentmethods
            additionalinfo
            _meta {
              tags
              uid
            }
          }
        }
      }
      second: allCompanys(
        lang: $locale
        sortBy: name_ASC
        after: "YXJyYXljb25uZWN0aW9uOjQy"
        first: 100
      ) {
        edges {
          cursor
          node {
            name
            logo
            background
            url
            list
            bonus
            bonussub
            freespins
            freespinssub
            rating
            weight
            depositinfo
            paymentmethods
            additionalinfo
            _meta {
              tags
              uid
            }
          }
        }
      }
      third: allCompanys(
        lang: $locale
        sortBy: name_ASC
        after: "YXJyYXljb25uZWN0aW9uOjE0Mg=="
        first: 100
      ) {
        edges {
          cursor
          node {
            name
            logo
            background
            url
            list
            bonus
            bonussub
            freespins
            freespinssub
            rating
            weight
            depositinfo
            paymentmethods
            additionalinfo
            _meta {
              tags
              uid
            }
          }
        }
      }
      allFooters(lang: $locale) {
        edges {
          node {
            about
            aboutus
            resources
            news
            dictionary
            legal
            disclaimer
            privacypolicy
            whatwedo
            whatwedotext
            responsiblegaming
            responsiblegamingtext
            contact
            contacttext
            bottomlinks
          }
        }
      }
      allKeywordss(lang: $locale) {
        edges {
          node {
            topic
            category
            date
            loadmore
            news
            categoryfilter
            glossary
            news
            aboutus
            integritypolicy
            privacypolicy
            disclaimer
            resources
            contact
            about
          }
        }
      }
    }
  }
`
